"use client";

import _ from "lodash";
import React, { createContext, useContext, useMemo, useState, useEffect, ReactNode } from "react";
import { useRouter, useParams } from "next/navigation";
import { environments } from "@/app/(docs)/routes-config";

interface DocumentContextType {
  selectedEnv: string;
  setSelectedEnv: (env: string) => void;
}

const INIT_STATE: DocumentContextType = {
  selectedEnv: environments[0].env,
  setSelectedEnv: () => {},
};

const Context = createContext<DocumentContextType>(INIT_STATE);

export function useDocumentContext() {
  return useContext(Context);
}

interface DocumentContextProviderProps {
  children: ReactNode;
}

export function DocumentContextProvider({ children }: DocumentContextProviderProps) {
  const router = useRouter();
  const params = useParams<{ slug: string[] }>();

  // Initialize selectedEnv with the value from the param in the url(/docs/[env]/...), or fallback to INIT_STATE.
  const [selectedEnv, setSelectedEnv] = useState<string>(_.first(params?.slug) || INIT_STATE.selectedEnv);

  // Redirect whenever selectedEnv changes
  useEffect(() => {
    const currentPath = window.location.pathname;
    const basePath = `/docs/${selectedEnv}`;

    // Check if the current path starts with the basePath
    if (selectedEnv && !currentPath.startsWith(basePath)) {
      router.push(basePath);
    }
  }, [selectedEnv, router]);

  const value = useMemo(
    () => ({
      selectedEnv,
      setSelectedEnv,
    }),
    [selectedEnv],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export default DocumentContextProvider;
