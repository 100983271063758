import _ from "lodash";

export interface Page {
  title: string;
  href: string;
}

// for page navigation & to sort on leftbar
const ROUTES = [
  {
    env: "ethereum",
    label: "Ethereum",
    pages: [
      {
        title: "Getting Started",
        href: "getting-started",
        items: [
          { title: "Introduction", href: "introduction" },
          { title: "EigenLayer AVS", href: "eigenlayer-avs" },
          // { title: "Product Roadmap", href: "roadmap" },
          { title: "Ways to Contact Us", href: "contact-us" },
          { title: "Frequently Asked Questions", href: "faq" },
        ],
      },
      {
        title: "Run EigenLayer Operator",
        href: "run-operator",
        items: [
          { title: "Operator Overview", href: "overview" },
          { title: "Setup Operator", href: "setup-node" },
          { title: "Setup Alias Key", href: "alias-key" },
          { title: "Secure Your Node", href: "secure-node" },
        ],
      },
      {
        title: "Develop",
        href: "develop",
        items: [
          { title: "Overview", href: "overview" },
          { title: "API References", href: "api-reference" },
          { title: "Javascript SDK", href: "js-sdk" },
        ],
      },
    ],
  },
  {
    env: "polkadot",
    label: "Polkadot",
    pages: [
      {
        title: "Network",
        href: "network",
        items: [
          { title: "Overview", href: "overview" },
          { title: "Bug Bounty Program", href: "bug-bounty" },
          { title: "Technical Papers", href: "papers" },
        ],
      },
      {
        title: "Run a Collator",
        href: "collators",
        items: [
          { title: "Collator Overview", href: "overview" },
          { title: "Set up a collator", href: "setup-collator-node" },
          { title: "Manage collator with systemd", href: "manage-with-systemd" },
          { title: "Upgrade a collator", href: "upgrade-collator-node" },
          { title: "Setup a collator node with partners", href: "setup-collator-node-partner" },
          { title: "Proxy collating permissions", href: "proxy-collator-permissions" },
        ],
      },
      {
        title: "Product Guide",
        href: "product-guide",
        items: [
          { title: "Overview", href: "overview" },
          { title: "Product Use Cases", href: "product-use-cases" },
          { title: "Time Automation Explained", href: "time-automation-explained" },
          { title: "Price Automation Explained", href: "price-automation-explained" },
          { title: "Develop Custom Workflow", href: "develop-custom-workflow" },
        ],
      },
      {
        title: "Develop",
        href: "develop",
        items: [
          { title: "Javascript SDK oak.js", href: "oak-js" },
          { title: "Pallet Development", href: "pallet-development" },
          { title: "Insights GraphQL API", href: "graphql-api" },
          { title: "Benchmarking", href: "benchmarking" },
        ],
      },
      {
        title: "Cross-chain Automation",
        href: "cross-chain-automation",
        items: [
          { title: "Cross-chain Product Integration", href: "integration-overview" },
          { title: "Establish HRMP channel with Turing", href: "hrmp-channel" },
        ],
      },
      {
        title: "Staking for Developer",
        href: "staking-dev",
        items: [{ title: "Delegation via Extrinsics", href: "delegation-dev" }],
      },
      {
        title: "Governance for Developer",
        href: "gov-dev",
        items: [
          { title: "Governance via Polkadot.js", href: "governance-via-polkadotjs" },
          { title: "Verify Runtime of Upgrade", href: "verify-runtime-upgrade" },
          { title: "Technical Committee Operations", href: "technical-committee" },
          { title: "Cancel a Referendum", href: "cancel-referendum" },
        ],
      },
      {
        title: "User Guide",
        href: "user-guide",
        items: [
          { title: "Get Testnet Tokens", href: "testnet-tokens" },
          { title: "Play with Testnet Tokens", href: "automation-on-testnet" },
          { title: "Set up On-chain Identity", href: "identity" },
          { title: "Use Multi-signature Wallet", href: "multi-sig-wallets" },
        ],
      },
      {
        title: "Governance for User",
        href: "gov-user",
        items: [
          { title: "Governance Overview", href: "governance-turing-overview" },
          { title: "Creating a Proposal", href: "governance-propose-change" },
          { title: "Voting on Proposals", href: "vote-on-proposal" },
          { title: "Batching Proposals", href: "governance-batching-proposals" },
        ],
      },
      {
        title: "Staking for User",
        href: "staking-user",
        items: [{ title: "Earn Staking Rewards", href: "delegation-user" }],
      },
    ],
  },
];

export const environments = _.map(ROUTES, (route) => ({ env: route.env, label: route.label }));

/**
 * Return the original ROUTES structure for left var navigation
 * e.g.
 * [{
    title: "Get Started",
    href: "ethereum/get-started",
    items: [
      { title: "Introduction", href: "ethereum/get-started/introduction" },
      { title: "Installation", href: "ethereum/get-started/installation" },
    ]
  },
  {
    title: "Server Actions",
    href: "ethereum/server-actions",
    items: [
      { title: "getSession", href: "ethereum/server-actions/get-session" },
      { title: "getToken", href: "ethereum/server-actions/get-token" },
    ]
  }
 */
export const pageRoutes = ROUTES.map((environment) => {
  const { env, pages } = environment;

  return {
    env,
    pages: _.map(pages, (page) => {
      const { title, href, items } = page;

      return {
        title,
        href: _.join([env, href], "/"),
        items: _.map(items, (item) => {
          return {
            title: item.title,
            href: _.join([env, href, item.href], "/"),
          };
        }),
      };
    }),
  };
});

// Function to generate paths and titles
const generatePaths = (routes: typeof ROUTES): Page[] => {
  const paths: Page[] = [];

  routes.forEach((route) => {
    route.pages.forEach((group) => {
      group.items.forEach((page) => {
        paths.push({
          title: page.title,
          href: `${route.env}/${group.href}/${page.href}`,
        });
      });
    });
  });

  return paths;
};

// Generate the paths with titles
export const flattenedPageRoutes = generatePaths(ROUTES);

/**
 * Returns the default href when only '/docs' is specified
 * Currently we simply return the first href of the first environment
 */
// export const defaultPage = flattenedPageRoutes[0];

/**
 * Returns the href of the first page if only a parent URL is provided
 * e.g. /docs will become /docs/ethereum/getting-started/automation-explained
 *     /docs/ethereum will become /docs/ethereum/getting-started/automation-explained
 *     /docs/ethereum/develop will become /docs/ethereum/develop/overview
 * TODO: to be implemented
 */
export const getDefaultPageHref = (input) => {
  const defaultPage = flattenedPageRoutes[0].href;

  let result = defaultPage;
  let firstMatchPath;

  switch (input) {
    case "/docs/ethereum":
      firstMatchPath = _.find(flattenedPageRoutes, (page) => _.startsWith(page.href, "ethereum"));
      if (firstMatchPath) {
        result = firstMatchPath.href;
      }
      break;
    case "/docs/ethereum/develop":
      // result = "/docs/ethereum/develop/api-reference";
      firstMatchPath = _.find(flattenedPageRoutes, (page) => _.startsWith(page.href, "ethereum/develop"));
      if (firstMatchPath) {
        result = firstMatchPath.href;
      }
      break;
    case "/docs/polkadot":
      firstMatchPath = _.find(flattenedPageRoutes, (page) => _.startsWith(page.href, "polkadot"));
      if (firstMatchPath) {
        result = firstMatchPath.href;
      }
      break;
    case "/docs/polkadot/develop":
      firstMatchPath = _.find(flattenedPageRoutes, (page) => _.startsWith(page.href, "polkadot/product-guide"));
      if (firstMatchPath) {
        result = firstMatchPath.href;
      }
      break;
    case "/docs":
    default:
      result = defaultPage;
      break;
  }

  return `/docs/${result}`; // Add /docs prefix to the returned path
};
